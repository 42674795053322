<template>
  <base-form
    v-if="editItem"
    :editStatus="true"
    :editItem="editItem"
    @update="handleSubmit"
    @cancel="handleCancel"
  />
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    props: {
      id: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        editItem: null
      }
    },

    computed: {
      ...mapGetters({
        currentEquipmentTemplate: 'equipmentTemplate/currentEquipmentTemplate'
      })
    },

    created () {
      if (this.id) {
        this.equipmentTemplateShow( this.id ).then( () => {
          this.editItem = this.currentEquipmentTemplate
        } )
      }
    },

    methods: {
      ...mapActions({
        equipmentTemplateShow: 'equipmentTemplate/show',
        equipmentTemplateUpdate: 'equipmentTemplate/update'
      }),
      handleSubmit (data) {
        this.equipmentTemplateUpdate(data).then(() => {
          this.$router.push({name: 'equipmentTemplate'})
        })
      },
      handleCancel() {
        this.$router.go(-1)
      }
    }
  }
</script>
